<template>
    <router-link :to="'/experiencias/' + product.fields.slug">
        <img :src="assets[product.fields.image[0].sys.id]" class="w-40 h-36 sm:w-48 sm:h-44 object-cover hover:opacity-80" />
    </router-link>
</template>

<script>
import { useRouter } from 'vue-router'

export default {
    props: ['product', 'assets'],
    setup() {
        const router = useRouter();

        return {
            router
        }
    }
}
</script>
