<template>
    <div class="text-center font-semibold text-base text-white mt-16 mb-6">
        {{category.name.toUpperCase()}}
    </div>

    <div class="flex flex-wrap justify-center gap-1">
        <div v-for="item in category.products" :key="item.id">
            <Producter 
                :product="item"
                :assets="assets" />
        </div>
    </div>
</template>

<script>
import Producter from '@/components/experiencias/Producter'
import { onMounted } from 'vue'

export default {
    props: ['category', 'assets'],
    components: {
        Producter
    }
}
</script>
