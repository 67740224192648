<template>
    <div class="mb-10" v-for="item in type.categories" :key="item.id">
        <Categorizer 
            :category="item"
            :assets="assets" />
    </div>
</template>

<script>
import Categorizer from '@/components/experiencias/Categorizer'

export default {
    components: {
        Categorizer
    },
    props: ['type', 'assets']
}
</script>