<template>
	<Menu />
	
	<div>
		<vueper-slides autoplay :arrows="false">
			<vueper-slide :image="state.slides[0].image"></vueper-slide>
			<vueper-slide :image="state.slides[1].image"></vueper-slide>
			<vueper-slide :image="state.slides[2].image"></vueper-slide>
			<vueper-slide :image="state.slides[3].image"></vueper-slide>
		</vueper-slides>

		<div class="mt-4">
			<div class="px-4">
				<div class="border-b border-gray-600">
					<nav class="-mb-px flex space-x-5 md:space-x-8">
						<div class="cursor-pointer" v-for="tab in state.tabs" :key="tab.name" @click="setSection(tab)" :class="[tab.current ? 'border-rojo text-rojo font-medium ' : 'border-transparent text-gray-300 hover:border-white hover:text-gray-50', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm']">
						{{ tab.name }}
						</div>
					</nav>
				</div>
			</div>
		</div>

		<div class="mb-24">
			<div v-for="item in state.finalTypes" :key="item.id">
				<Typer 
					:type="item"
					:assets="state.assets" />
			</div>
		</div>
	</div>
</template>

<script>
import Menu from '@/components/core/Menu'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import { onMounted, reactive } from 'vue'
import Typer from '@/components/experiencias/Typer'
import _ from 'lodash'

let contentful = require('contentful')
const client = contentful.createClient({
    space: 'eiwnd9wc1dc0',
    accessToken: 'Kj2r0cqWM0-5r1kazWBP_ax-Z7BX0AFxWvcsD_LsS8g'
});

export default {
	components: {
		Menu,
		VueperSlides, 
		VueperSlide,
		Typer
	},
	setup() {
		const state = reactive({
			loaded: false,
			slides: [
				{ image: require('@/assets/experiencias/sliders/buchanans.jpg') },
				{ image: require('@/assets/experiencias/sliders/invitacion.jpg') },
				{ image: require('@/assets/experiencias/sliders/pasaporte_palaciodehierro.jpg') },
				{ image: require('@/assets/experiencias/sliders/tecate.png') }
			],
			tabs: [],
			collection: null,
			categories: [],
			types: [],
			finalTypes: [],
			assets: {},
			section: null
		});

		onMounted(() => {
			getCategories();
			state.section = window.location.href.split('#')[1];
		});

		function getCategories() {
			client.getEntries({
				limit: 1000,
				content_type: 'category'
			}).then(response => {
				response.items.forEach(dt => {
					state.categories.push({id: dt.sys.id, name: dt.fields.name, order: dt.fields.order});
				});

				getTypes();
			});
		}

		function getTypes() {
			client.getEntries({
				limit: 1000,
				content_type: 'type'
			}).then(response => {
				response.items.forEach(dt => {
					let obj = {
						id: dt.sys.id, 
						name: dt.fields.name,
						refran: dt.fields.refran,
						order: dt.fields.order,
						categories: []
					};

					dt.fields.categories.forEach(mk => {
						state.categories.forEach(hj => {
							if (hj.id == mk.sys.id) {
								obj.categories.push({id: mk.sys.id, name: hj.name, order: hj.order});
							}
						});
					});

					state.types.push(obj);
				});

				getProducts();
			});
		}

		function getProducts() {
			client.getEntries({
				limit: 1000,
				content_type: 'product'
			}).then(response => {
				setAssets(response.includes.Asset);

				response.items.forEach(dt => {
					state.types.forEach(mk => {
						if (mk.id == dt.fields.type[0].sys.id) {
							mk.categories.forEach(hg => {
								if (hg.id == dt.fields.category[0].sys.id) {
									if (hg.products == undefined) {
										hg.products = [];
									}
									hg.products.push(dt);
								}
							});

							mk.categories = _.sortBy(mk.categories, 'order');

							mk.categories.forEach(hg => {
								if (hg.products) {
									hg.products = _.sortBy(hg.products, 'fields.order');
								}     
							});
						}
					});
				});

				state.types = _.sortBy(state.types, 'order');

				state.types.forEach(dt => {
					state.tabs.push({
						name: dt.name,
						section: dt.id,
						current: false
					})
				});

				if (state.section) {
					let index = state.tabs.findIndex(item => {return item.name.toLowerCase() == state.section});
					setSection(state.tabs[index]);
				} else {
					setSection(state.tabs[0]);
				}
				
				setFinalTypes();
				state.loaded = true;
			});
		}

		function setFinalTypes() {
			state.finalTypes = [];

			state.types.forEach(dt => {
				if (dt.id == state.collection.section) {
					state.finalTypes.push(dt);
				}
			})
		}

		function setAssets(data) {
            data.forEach(dt => {
                state.assets[dt.sys.id] = dt.fields.file.url;
            });
		}

		function setSection(tab) {
			state.tabs.forEach(dt => {
				dt.current = false;
			});

			state.collection = state.tabs.find(item => {return item.section == tab.section});
			state.collection.current = true;

			setFinalTypes();
		}

		return {
			state,
			setSection
		}
	}
}
</script>
